/* eslint-disable css-modules/no-undef-class */
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Typography from 'components/atoms/Typography';
import DownloadButton from 'components/atoms/DownloadButton';
import Link from 'containers/atoms/LinkContainer';
import getImages from 'utils/getImages';
import getTypeIcon from 'utils/getTypeIcon';
import generateSizes from 'utils/generateSizes';
import s from './RelatedArticle.css';

const RelatedArticle = ({
  classes,
  contentType,
  relationTitle,
  binary,
  imageSrc,
  title,
  href,
  imageAlt,
  imageData,
  darkMode,
}) => {
  const standardWidthInPixels = 74;
  const standardHeightInPixels = 74;
  const classNames = classnames(classes, s.relatedArticle, imageSrc && s.haveImage, darkMode && s.darkMode);
  const imageSizes = generateSizes({ default: `${standardWidthInPixels}px` });
  let readMoreHeader = relationTitle;

  const openLink = (event, url) => {
    if (window) {
      window.open(url, '_blank');
      event.preventDefault();
    }
  };

  if (!readMoreHeader) {
    readMoreHeader = contentType === 'report' ? 'Rapport' : 'Läs också';
  }

  const imageAttributes = {
    src: getImages(contentType === 'report' ? 'FREEFORM' : 'SQUARE').toSrc(imageSrc, 'XS'),
    srcSet: getImages(contentType === 'report' ? 'FREEFORM' : 'SQUARE').toSrcSet(imageSrc),
    sizes: imageSizes,
    alt: imageAlt,
    width: standardWidthInPixels,
  };

  // 1. If not report, make it square
  // 2. Otherwise check if the image has been edited and set the height based on the edited images aspect ratio
  // 3. Otherwise use the original aspect ratio to set the height
  if (contentType !== 'report') {
    imageAttributes.height = standardHeightInPixels;
    imageAttributes.lazyLoadWrapperHeight = standardHeightInPixels;
  } else if (imageData?.freeFormSourceWidth && imageData?.freeFormSourceHeight) {
    imageAttributes.height =
      standardWidthInPixels / (imageData.freeFormSourceWidth / imageData.freeFormSourceHeight);
    imageAttributes.lazyLoadWrapperHeight = imageAttributes.height;
  } else if (imageData?.originalSize?.width && imageData?.originalSize?.height) {
    const originalWidth = imageData.originalSize.width;
    const originalHeight = imageData.originalSize.height;

    if (originalWidth && originalHeight) {
      const derivedHeight = Math.round(
        standardWidthInPixels / (parseInt(originalWidth, 10) / parseInt(originalHeight, 10))
      );
      imageAttributes.height = derivedHeight || standardHeightInPixels;
      imageAttributes.lazyLoadWrapperHeight = derivedHeight || standardHeightInPixels;
    }
  }

  const renderButton = (mobile = false) => (
    <DownloadButton
      onClick={e => openLink(e, binary.href)}
      classes={classnames(s.downloadButton, mobile && s.downloadButtonMobile)}
    >
      <FontAwesomeIcon icon={getTypeIcon(binary.extension, true)} className={s.fileIconDownload} />
      Ladda ner
    </DownloadButton>
  );

  return (
    <div className={classNames}>
      {imageSrc && (
        <div className={classnames(s.imageWrapper, contentType === 'report' && s.freeForm)}>
          <ResponsiveImage {...imageAttributes} />
        </div>
      )}
      <div className={s.textContainer}>
        <Typography variant="readMoreHeader" classes={s.readMoreHeader} darkMode={darkMode}>
          {readMoreHeader}
        </Typography>
        <Typography variant="readMoreItem" component={Link} to={href} classes={s.title} darkMode={darkMode}>
          {title}
        </Typography>
        {contentType === 'report' && binary && renderButton(true)}
      </div>
      {contentType === 'report' && binary && renderButton()}
    </div>
  );
};

export default withStyles(s)(RelatedArticle);
