import React, { useReducer } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { AnimatePresence, motion } from 'framer-motion';
import NumberFormat from 'react-number-format';
import Slider from '../../atoms/Slider/Slider';
import ButtonPrimaryAlt from '../../atoms/buttons/ButtonPrimaryAlt';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import vfsFormReducer, { CONSTANTS, TYPES, initialState } from './vfsFormReducer';
import s from './VFSForm.css';
import useNavigate from '../../../utils/hooks/useNavigate';
import base64 from '../../../utils/base64';
import Typography from '../../atoms/Typography';
import { calcAntalValfardtjanst, calcKey, VALFARDSTJANSTER } from '../../../utils/vfsUtils';
import TempusifiedValfardstjanst from '../../atoms/TempusifiedValfardtjanst/TempusifiedValfardtjanst';
import sentencifyArray from '../../../utils/sentencifyArray';
import { VFS_SALT } from '../../../serverRoutes/valfardsskaparnaPlainParamsHandlerRoute';

const VFSForm = ({ darkMode }) => {
  const [state, dispatch] = useReducer(vfsFormReducer, initialState);
  const navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();

    const { companyName, antalAnstallda, lonekostnaderPerAr, bolagsskattPerAr } = state;
    const sumKey = base64.encode(calcKey({ companyName, antalAnstallda, salt: VFS_SALT }));

    const formula = JSON.stringify({
      companyName,
      antalAnstallda,
      lonekostnaderPerAr,
      bolagsskattPerAr,
    });
    const data = base64.encode(formula);
    navigate(
      `/valfardsskaparna/resultat/?vfsdata=${encodeURIComponent(data)}&k=${encodeURIComponent(sumKey)}`
    );
  };

  const handleRangeChange = {
    companyName: e => dispatch({ type: TYPES.SET_COMPANY_NAME, payload: e.target.value }),
    antalAnstallda: e => dispatch({ type: TYPES.SET_ANTAL_ANSTALLDA, payload: parseInt(e.target.value, 10) }),
    lonekostnaderPerAr: e =>
      dispatch({ type: TYPES.SET_LONEKOSNADER_PER_AR, payload: parseInt(e.target.value, 10) }),
    bolagsskattPerAr: e =>
      dispatch({ type: TYPES.SET_BOLAGSSKATT_PER_AR, payload: parseInt(e.target.value, 10) }),
  };

  const resultsToRender = [
    VALFARDSTJANSTER.polis,
    VALFARDSTJANSTER.forskoleplats,
    VALFARDSTJANSTER.gymnasielarare,
    VALFARDSTJANSTER.underskoterskaIHemtjanst,
  ]
    .map(valfardstjanst => ({
      valfardstjanst,
      nr: calcAntalValfardtjanst(state.lonekostnaderPerAr, state.bolagsskattPerAr, valfardstjanst),
    }))
    .filter(item => item.nr > 0)
    .map(item => (
      <TempusifiedValfardstjanst
        number={calcAntalValfardtjanst(state.lonekostnaderPerAr, state.bolagsskattPerAr, item.valfardstjanst)}
        valfardstjanst={item.valfardstjanst}
      />
    ));

  const shouldShowResults = resultsToRender.length > 0 && state.companyName;

  return (
    <form className={s.vfsForm} onSubmit={handleSubmit}>
      <div className={s.inputs}>
        <label htmlFor="companyName" className={s.textInputLabel}>
          Företagets namn
          <input type="text" id="companyName" onChange={handleRangeChange.companyName} />
        </label>
        <Slider
          value={state.antalAnstallda}
          onChange={handleRangeChange.antalAnstallda}
          className={s.slider}
          label="Antal anställda i Sverige"
          min={1}
          max={CONSTANTS.MAX_ANSTALLDA}
          withTextInput
        />
        <Slider
          value={state.lonekostnaderPerAr}
          onChange={handleRangeChange.lonekostnaderPerAr}
          className={s.slider}
          label="Lönekostnader per år"
          min={CONSTANTS.AVG_LON_PER_AR}
          max={CONSTANTS.MAX_LONEKOSTNADER_PER_AR}
          disabled={!state.steerIndividualValues}
          withTextInput
          textInputComponent={textInputProps => (
            <NumberFormat
              {...textInputProps}
              onValueChange={({ floatValue }) =>
                dispatch({ type: TYPES.SET_LONEKOSNADER_PER_AR, payload: floatValue })
              }
              isAllowed={({ floatValue }) => floatValue >= 1}
              thousandSeparator=" "
              suffix=" kr"
            />
          )}
        />
        <Slider
          value={state.bolagsskattPerAr}
          onChange={handleRangeChange.bolagsskattPerAr}
          className={s.slider}
          label="Bolagsskatt per år"
          min={1}
          max={CONSTANTS.MAX_BOLAGSSKATT_PER_AR}
          disabled={!state.steerIndividualValues}
          withTextInput
          textInputComponent={textInputProps => (
            <NumberFormat
              {...textInputProps}
              onValueChange={({ floatValue }) =>
                dispatch({ type: TYPES.SET_BOLAGSSKATT_PER_AR, payload: floatValue })
              }
              isAllowed={({ floatValue }) => floatValue >= 1}
              thousandSeparator=" "
              suffix=" kr"
            />
          )}
        />
      </div>
      <Checkbox
        id="steerIndividualValues"
        label="Justera lönekostnad och bolagsskatt manuellt"
        checked={state.steerIndividualValues}
        onChange={() => dispatch({ type: TYPES.SET_STEER_INDIVIDUAL_VALUES })}
      />

      <AnimatePresence>
        {shouldShowResults && (
          <motion.div
            className={s.results}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={s.resultsInner}>
              <Typography className={s.result} component="p" variant="h2" darkMode={darkMode}>
                <span>
                  <strong>{state.companyName}</strong> bidrar med{' '}
                  {sentencifyArray(resultsToRender, {
                    lastSeparator: 'eller',
                  })}
                  .
                </span>
              </Typography>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <ButtonPrimaryAlt type="submit" disabled={!state.companyName} className={s.cta}>
        Se välfärden ni skapar
      </ButtonPrimaryAlt>
    </form>
  );
};

export default withStyles(s)(VFSForm);
