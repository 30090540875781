import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Link from 'containers/atoms/LinkContainer';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import s from './LinkButton.css';
import clientifyUrl from '../../../utils/clientifyUrl';

// eslint-disable-next-line no-unused-vars
const LinkButton = ({ text = '', variant = '', anchorName, href, color, isExternal, style, className }) => {
  if (variant === 'facebook') {
    return (
      <Link to={href} target="_blank" className={classnames(s.linkButton, s.facebookButton)}>
        <FontAwesomeIcon icon={faFacebookF} style={{ width: 10, height: 16, marginRight: 10 }} />
        {text}
      </Link>
    );
  }

  const url = !anchorName ? href : !anchorName.startsWith('#') ? `#${anchorName}` : anchorName;

  return (
    <Link
      to={clientifyUrl(url)}
      {...(isExternal ? { target: '_blank' } : {})}
      {...(style ? { style } : {})}
      className={classnames(s.linkButton, {
        [s.orange]: color === 'orange' || !color,
        [s.steel]: color === 'steel',
        [className]: !!className,
      })}
    >
      {text}
    </Link>
  );
};

export default withStyles(s)(LinkButton);
