/* eslint-disable dot-notation */
import * as Sentry from '@sentry/react';
import { createSelector } from 'reselect';
import { selectors as locationSelectors } from 'ducks/location';
import { selectors as cookSelectors } from 'ducks/cook';
import pickFrom from 'utils/pickFrom';
import getOgImage from './getOgImage';
import getOgDescription from './getOgDescription';
import getStructuredStorylineTree from '../getStructuredStorylineTree';
import getMicrositeOgDescription from './getMicrositeOgDescription';

export default createSelector(
  cookSelectors.getData,
  getStructuredStorylineTree,
  getOgDescription,
  getMicrositeOgDescription,
  getOgImage,
  locationSelectors.getFullPath,
  (data, elements, ogDescription, openGraphDescription, ogImage, fullPath) => {
    let title;
    if (data || elements) {
      try {
        const headlineElement = elements.find(el => el.type === 'headline');
        if (headlineElement && !headlineElement.fields?.headline) {
          Sentry.captureMessage('Empty headline on article', {
            tags: {
              url: fullPath,
            },
          });
        }
        if (headlineElement?.fields.headline) {
          title = headlineElement.fields.headline.rawText;
        } else {
          title = pickFrom(data, 'context.title');
        }
        const micrositeOpenGraphTitleTitle = data?.microsite?.fields?.openGraphTitle;
        title =
          micrositeOpenGraphTitleTitle === '' || undefined ? title : micrositeOpenGraphTitleTitle || title;
        return {
          headline: title,
          href: fullPath,
          description: ogDescription || openGraphDescription,
          image: ogImage.href,
          imageWidth: ogImage.width,
          imageHeight: ogImage.height,
        };
      } catch (err) {
        return undefined;
      }
    }
    return undefined;
  }
);
