import React from 'react';
import classnames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './LinkButtonGroup.css';
import LinkButton from '../../molecules/LinkButton/LinkButton';

const LinkButtonGroup = ({ buttons = [] }) => {
  return (
    <div className={classnames(s.linkButtonGroup, `${buttons[0]?.alignment === 'center' ? s.center : ''}`)}>
      {buttons.map(({ text, alignment, anchorName, href, variant, color, id }) => (
        <LinkButton
          key={id}
          text={text}
          variant={variant}
          color={color}
          align={alignment}
          anchorName={anchorName}
          href={href}
        />
      ))}
    </div>
  );
};

export default withStyles(s)(LinkButtonGroup);
