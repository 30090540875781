import React, { useEffect, useState } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Typography from '../../atoms/Typography';
import s from './VFSTaxTicker.css';
import formatNumber from '../../../utils/formatNumber';

const TAX_PER_SECOND = 57317.192;
const TAX_PER_MS = TAX_PER_SECOND / 1000;

const getCurrentTax = () => {
  const now = new Date();
  const start = new Date(new Date().getFullYear(), 0, 1);
  const diff = now - start;

  return diff * TAX_PER_MS;
};

const VFSTaxTicker = ({ darkMode }) => {
  const [tax, setTax] = useState(getCurrentTax());

  useEffect(() => {
    const interval = setInterval(() => {
      setTax(getCurrentTax());
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <Typography
      suppressHydrationWarning
      className={s.vfsTaxTicker}
      darkMode={darkMode}
      component="p"
      variant="h1"
    >
      {formatNumber(tax)} kr
    </Typography>
  );
};

export default withStyles(s)(VFSTaxTicker);
